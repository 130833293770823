import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/home"),
  },
  {
    path: "/sms",
    name: "sms",
    component: () => import("../views/sendSms"),
  },
  {
    path: "/find/:id",
    name: "find",
    component: () => import("../views/share"),
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  window.gtag &&
    window.gtag("config", "UA-194277850-1", { page_path: to.fullPath, page_title: to.name });
});

export default router;
