import "./plugins/httpService";
import i18n from "./plugins/i18n";

import "./sass/reset.scss";
import "./sass/common.scss";

import Vue from "vue";
import App from "./App.vue";

import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

window.vm = new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
