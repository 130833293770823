import { httpService } from "../common/vue-plugin/httpService";
import { root } from "@/common/config";
export const country = (data = {}) =>
  httpService(
    {
      url: `${root}/country/selectAllCountries`,
      method: "get",
      isNeedLoading: false,
    },
    data,
  );

export const currentCoordinates = (data = {}) =>
  httpService(
    {
      url: `${root}/country/currentCoordinates`,
      method: "get",
      isNeedLoading: false,
    },
    data,
  );

export const selectItemByLanguageCode = (data = {}) =>
  httpService(
    {
      url: `${root}/i18n/selectItemByLanguageCode`,
      method: "get",
      isNeedLoading: false,
    },
    data,
  );
