<template>
  <v-dialog
    transition="dialog-top-transition"
    :value="show"
    max-width="584px"
    persistent
    no-click-animation
  >
    <v-card rounded="xl" elevation="1">
      <v-toolbar dense :color="themeColor" dark flat rounded="24px">
        <div class="d-flex justify-end title">
          <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
        </div>
      </v-toolbar>
      <div class="content">
        <div class="help-title text-h4 text-center">{{ $t("lang.home.help.title") }}</div>
        <div class="text-h6 help-subtitle">{{ $t("lang.home.help.subTile1") }}</div>
        <v-card-text class="help-content">{{ $t("lang.home.help.content1") }}</v-card-text>
        <div class="text-h6 help-subtitle mt20">{{ $t("lang.home.help.subTile2") }}</div>
        <v-card-text class="help-content">{{ $t("lang.home.help.content2") }}</v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { themeColor } from "@/common/const";
export default {
  data() {
    return {
      themeColor,
      show: false,
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  width: 100%;
}
.mt20 {
  margin-top: 20px;
}
.help-subtitle {
  padding: 0 16px;
}
.help-title {
  color: #6c5be7;
  margin: 20px 0;
}
.help-content {
  padding: 10px 16px;
}
</style>
