import Vue from "vue";
import httpService from "@/common/vue-plugin/httpService";
import { throttle } from "lodash";
import store from "../store/index";

Vue.use(httpService, {
  // 默认错误
  messageFn: throttle(function (type, msg) {
    store.commit("mergeState", { snackbar: { show: true, msg, type: "error" } });
  }, 4000),
  requestInterceptor(config) {
    const nconfig = config;
    nconfig.headers.common["Accept-Language"] = window.vm.$getLang();
    return nconfig;
  },
  authentication: false, // 是否需要开启鉴权；
});
