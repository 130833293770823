import Vue from "vue";
import loadLang, { i18n } from "../common/vue-plugin/loadLang";
import { selectItemByLanguageCode } from "@/service/common";
// import paypal from "@/assets/paypal.png";

// const loadStripe = (description) => {
//   const payForm = document.querySelector("#paypalForm");
//   const scriptEle = document.createElement("script");
//   scriptEle.className = "stripe-button";
//   const data = {
//     key: "pk_live_51InP56DbJX4FKyISUqM2Wc1cJ0lph6HLxpr2Ef1CnMeq2aX2LOv14e7LorJEYTcrAbFCmMvM54Wk6JEiMsW8k3vN00YfQ0jNQq",
//     image: paypal,
//     description,
//     currency: "USD",
//     locale: "auto",
//     amount: "1000",
//   };
//   scriptEle.src = "https://checkout.stripe.com/checkout.js";
//   Object.keys(data).forEach((key) => (scriptEle.dataset[key] = data[key]));
//   payForm.appendChild(scriptEle);
// };

Vue.use(loadLang, {
  async loadProject(languageCode, next) {
    // todo加载语言包；
    const params = { languageCode };
    const { data, code } = await selectItemByLanguageCode(params);
    if (Number(code)) {
      next();
      return;
    }
    let { list } = data;
    list = list.reduce((pre, cur) => Object.assign(pre, { [cur.itemCode]: cur.content }), {});
    document.title = list["lang.title"] || "phone-locator";
    // setTimeout(() => {
    //   loadStripe(document.title);
    // }, 10);
    next(list);
  },
});
export default i18n;
