/**
 *  代理处理国际化相关
 *
 *  1. 代处理libs语言包导入和i18n桥接
 *  2. 代处理elementUi语言包导入和i18n桥接
 *  3. 代管理语言处理逻辑。（增加，删除， 修改， 查询）
 *  4. 代处理语言转换， （需要配置是否增加区域）
 *
 *  @param { String } lang; // 初始化语言，不传有默认；
 *  @param { Function } mappingLanguage;
 *     1. 如果传方法，则必须返回语言项， 实际场景会存在en_us,en,en_CA,可以统一修改为en
 *     2. 如果不传，默认不支持地区性语言，即 en_us,en,en_CA为en；
 *     3. 建议不覆盖默认的Function
 *  @param { [Array<Promise> | Promise]Function } loadExtendLibs;
 *     1.如果不为空，则必须有返回值；
 *  @param { [Object] Function } loadProject;
 *     1. 如果不为空，则必须返回值；
 *
 *  备注，
 *    1.  默认后台接入的是架构组那边的国际化后台接口， 因为架构组那边的请求参数，是由 [语言]_[地区构成]和前端略微有写不一样。
 *    2.  限制于当前element不支持地区，所以前端只能设置语言；
 */

import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

// 默认配置项；
const defaultOptions = {
  lang: window.navigator.language,
  // 建议不覆盖；
  mappingLanguage(lang) {
    let curLang = lang;
    switch (lang) {
      case "zh-CN":
      case "zh-cn":
      case "zh-TW":
      case "zh":
        curLang = "zh-CN";
        break;
      case "en_us":
      case "en":
      case "en-US":
      case "en-GB":
      case "en-CA":
        curLang = "en";
        break;
      case "ja-jp":
      case "ja":
        curLang = "ja";
        break;
      case "de":
      case "de-DE":
      case "de_DE":
        curLang = "de";
        break;
      default:
        break;
    }
    return curLang;
  },
  loadExtendLibs: null,
  loadProject: null,
};

// 映射语言和语言地区
const mapLanguageToLocal = {
  zh: "zh-CN",
  en: "en-US",
  ja: "ja-JP",
  de: "de-DE",
};

const i18n = new VueI18n({ silentTranslationWarn: true });

const loadLang = {
  i18n,
};

class Language {
  constructor(options) {
    this.i18n = options.i18n;
    this.lang = options.lang;
    this.extendLibs = options.loadExtendLibs;
    this.loadProject = options.loadProject;
    this.mappingLanguage = options.mappingLanguage;
    this.loadedLanguages = [];
  }
  // 加载所有的libs包；
  _loadLibsLanguages(lang) {
    return new Promise((resolve, reject) => {
      const map = { "zh-CN": "zh-Hans", "zh-cn": "zh-Hans" };
      let libs = [import(`vuetify/lib/locale/${map[lang] || lang}`)];
      if (
        this.extendLibs &&
        Object.prototype.toString.call(this.extendLibs) === "[object Function]"
      ) {
        const result = this.extendLibs(lang);
        libs = libs.concat(Array.isArray(result) ? result : [result]);
      }
      Promise.all(libs)
        .then((values) => {
          this._mergeLanugage(
            lang,
            values.reduce((preValue, curValue) => ({ ...preValue, ...curValue.default }), {}),
          );
          resolve();
        })
        .catch((error) => {
          reject();
          return Promise.reject(new Error(`加载libs库报错 \n ${error}`));
        });
    });
  }
  // 加载用户请求包
  _loadProjectLanguages(lang) {
    return new Promise((resolve) => {
      this.loadProject(this._getRequestLanguage(lang), (data) => {
        this._mergeLanugage(lang, Object.freeze(data));
        resolve();
      });
    });
  }

  // 转换后台需要的language
  _getRequestLanguage(lang) {
    // let res = this.mappingLanguage(lang);
    // if (!~lang.indexOf("-")) {
    //   res = mapLanguageToLocal[lang] || lang;
    // }
    // return String(res).replace("-", "_").toLocaleLowerCase();
    return lang;
  }

  // 设置当前语言；
  _setI18nLanguage(lang) {
    this.i18n.locale = lang;
    this.lang = lang;
    localStorage.setItem("language", lang);
    if (!this.loadedLanguages.includes(lang)) {
      this.loadedLanguages.push(lang);
      import("axios")
        .then(
          (g) => (g.defaults.headers.common["Accept-Language"] = this._getRequestLanguage(lang)),
        )
        .catch(() => console.log("不存在axios库"));
    }
    return lang;
  }

  // 合并值
  _mergeLanugage(lang, data) {
    this.i18n.mergeLocaleMessage(lang, data);
  }
  // 语言改变
  changeLanguage(lang, successCb, failCb) {
    const myLang = this.mappingLanguage(lang);
    if (!this.loadedLanguages.includes(myLang)) {
      Promise.all([this._loadProjectLanguages(myLang)])
        .then(() => {
          successCb && successCb();
          this._setI18nLanguage(myLang);
        })
        .catch(() => {
          failCb && failCb();
          return Promise.reject("加载语言报错");
        });
    } else {
      this._setI18nLanguage(myLang);
    }
  }
  _main() {
    this.changeLanguage(this.lang);
  }
}

let getLang = null;
let setLangs = null;

loadLang.install = (vue, options) => {
  const moptions = Object.assign(defaultOptions, options, { i18n });
  // 使用VueI18n
  // 新建语言对象
  const languageItem = new Language(moptions);
  languageItem._main();
  // 更新属性
  vue.prototype.$getLang = getLang = () => languageItem.lang;
  // 更改属性
  vue.prototype.$setLangs = setLangs = function () {
    languageItem.changeLanguage(...arguments);
  };
};

export { i18n, getLang, setLangs };
export default loadLang;
