<template>
  <div class="phone-locator__box">
    <v-app>
      <v-app-bar app :color="themeColor" dark :class="`${isHome ? 'app-bar' : ''}`">
        <div class="app-bar-box">
          <div :class="`${xs ? 'text-h6' : 'text-h5'} poi`" @click="$router.push('/')">
            {{ $t("lang.bar.title") }}
          </div>
          <div class="icon-help poi" v-if="isHome" @click="handleHelp"></div>
        </div>
      </v-app-bar>
      <v-main>
        <router-view />
      </v-main>
    </v-app>
    <!-- 公共组件 -->
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      top
      centered
      :color="snackbarColor"
    >
      {{ snackbar.msg }}
    </v-snackbar>
    <!-- help-dialog -->
    <help-dialog ref="helpDialog"></help-dialog>
  </div>
</template>

<script>
import { themeColor } from "@/common/const";
import helpDialog from "@/components/helpDialog";
import { mapState } from "vuex";

export default {
  name: "App",
  components: { helpDialog },
  data: () => ({
    themeColor,
  }),
  computed: {
    ...mapState(["snackbar"]),
    snackbarColor() {
      return { error: "#f44336" }[String(this.snackbar.type) || "error"];
    },
    xs() {
      return this.$vuetify.breakpoint.xs;
    },
    isHome() {
      return this.$route.name === "Home";
    },
  },
  methods: {
    handleHelp() {
      this.$refs.helpDialog.open();
    },
  },
};
</script>
<style lang="scss" scoped>
.app-bar {
  z-index: 999 !important;
}
.app-bar-box {
  padding: 0 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  .icon-help {
    background: url("~@/assets/lookIcon/help.png") no-repeat center center;
    background-size: contain;
    width: 20px;
  }
}
</style>
