import { country, currentCoordinates } from "@/service/common";
export default {
  state: {
    snackbar: { show: false, type: "error", timeout: 2000, msg: "network error !" },
    countryArr: [],
    currentCoordinates: {},
  },
  getters: {},
  actions: {
    async getCountry({ commit }) {
      const { data } = await country();
      const { list } = data;
      if (!list || !list.length) return;

      commit("replaceState", { countryArr: list });
    },
    async getCurrentCoordinates({ commit }) {
      const { data, code } = await currentCoordinates();
      if (!code) return;
      commit("replaceState", { currentCoordinates: data });
    },
  },
  mutations: {
    mergeState(state, payload) {
      const keys = Object.keys(payload);
      keys.map((item) => {
        state[item] = Object.assign({}, state[item], payload[item]);
        return true;
      });
    },
    replaceState(state, payload) {
      const keys = Object.keys(payload);
      keys.map((item) => {
        state[item] = payload[item];
        return true;
      });
    },
  },
};
